<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="container fn text-white mt-2">
      <div>
        <div>
          <span>
            <router-link :to="{ name: 'dashboard-hitler' }">
              <i class="fad fa-angle-double-left fa-2x" />
            </router-link>
          </span>
        </div>
        <div>
          <h3 class="text-dark">
            ข้อมูลสำหรับชวนเพื่อน
          </h3>
        </div>
        <div class="balance-cl p-1 mb-2">
          <div class="d-flex justify-content-between">
            <div>
              <span class="text-gray">รหัสชวนเพื่อน</span>
              <p class="highlight">
                {{ User.invite_code }}
              </p>
            </div>

            <vue-qrcode
              type="image/jpeg"
              height="90"
              :value="`${ Agent.site}/register-ufa?ref=${User.invite_code}`"
            />
          </div>

        </div>
        <div>
          <span class="text-dark">ลิงก์ชวนเพื่อน</span> <br>
          <small class="text-dark">{{ Agent.site }}/register-ufa?ref={{ User.invite_code }}</small>
          <br>
          <button
            v-clipboard:copy="message"
            class="copy text-dark"
            @click="showToast1()"
          >
            <i class="fad fa-copy" />
            คัดลอกลิ้งค์
          </button>
        </div>
        <hr>
        <h4 class="text-dark">
          รายการชวนเพื่อน
          <i
            style="color: #ee7522"
            class="fad fa-redo-alt"
            @click="getUser(userData)"
          />
        </h4>
        <hr>
        <b-row
          v-for="(key, index) in friendData"
          :key="index.id"
          class="neon text-center mt-1 mb-1 text-dark"
        >
          <b-col cols="3">
            ยูส
          </b-col>
          <b-col cols="3">
            ฝาก
          </b-col>
          <b-col cols="3">
            เครดิตคงเหลือ
          </b-col>
          <b-col cols="3">
            ถอน
          </b-col>
          <b-col
            md="3"
            cols="3"
            class="p-0"
          >
            <small style="margin-top: 10px">
              {{ key.username }}
            </small>
          </b-col>
          <b-col cols="3">
            <small>{{ key.firstdeposit }}</small><br>
          </b-col>
          <b-col cols="3">
            <small>{{ key.balance }}</small><br>
          </b-col>
          <b-col
            cols="3"
            @click="submit(key)"
          >
            <i
              v-if="key.invstatus === 0"
              class="fad fa-donate"
            />

            <i
              v-if="key.invstatus === 1"
              class="fad fa-donate"
              style="color: #ee7522"
            />
          </b-col>
        </b-row>
        <hr>
        <small
          style="color: #ee7522"
        >*คลิกที่ ไอคอน เพื่อโยกเครดิตเชิญเพื่อนเข้ากระเป๋าของท่าน</small>
        <br><br>
        <h4 class="text-dark">
          กติกาการโยกเงินเข้ากระเป๋าของท่าน
        </h4>

        <small class="text-dark">
          1. เพื่อนต้องมีเครดิตคงเหลืออย่างน้อย 5 บาท หรือทำเทิร์น 1 เท่า<br>
          2. คนชวนจะได้รับโบนัสคิด % ของยอดฝากครั้งแรก<br>
          3. คนชวนจะสามารถถอนโบนัส ได้เลยโดยทำยอดเทิร์น 1เทิร์นเท่านั้น<br>
        </small>
        <br><br><br><br>
        <br>
        <br>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  // BInputGroup,
  // BFormInput,
  BRow,
  BCol,
  BOverlay,
  BIconController,
  // BBadge,
} from 'bootstrap-vue'
// import moment from 'moment-timezone'
import VueQrcode from 'vue-qrcode'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import VueClipboard from 'vue-clipboard2'

export default {
  components: {
    // BInputGroup,
    // BFormInput,
    BRow,
    BCol,
    BOverlay,
    BIconController,
    VueQrcode,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // BBadge,
  },
  data() {
    return {
      show: true,
      message: '',
      balance: '',
      amount: '',
      friendData: '',
      userData: JSON.parse(localStorage.getItem('userData')),
      User: '',
      Agent: '',
    }
  },
  mounted() {
    // this.getbalance(this.userData.username)
    this.getUser(this.userData)
    this.getAgent()
  },
  methods: {
    showToast1() {
      this.$toast.success('คัดลอกลิ้งสำเร็จ', {
        position: 'top-right',
        timeout: 3475,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: 'fad fa-paste',
        rtl: false,
      })
      this.message = `${this.Agent.site}/register-ufa?ref=${this.User.invite_code}`
    },
    showToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'คัดลอกลิ้งค์สำเร็จ',
          icon: 'CopyIcon',
          text: '👋 คัดลอกข้อความเรียบร้อยแล้วนะคะ',
          variant,
        },
      })
    },
    getfriend(id) {
      this.show = true
      this.$http
        .get(`https://api.ufabangkok.com/api/promotion/friendlistturn/${id}`)
        .then(response => {
          // console.log(response.data)
          this.show = false
          this.friendData = response.data
          // let index = 0
          // this.withdrawData.forEach(items => {
          //   this.withdrawData[index].timestamp = moment(items.created_at)
          //     .tz('Asia/Bangkok')
          //     .format('DD/MM/YYYY HH:mm:ss')
          //   index += 1
          // })
        })
        .catch(error => console.log(error))
    },
    getUser(userData) {
      this.show = true
      this.$http
        .get(`https://api.ufabangkok.com/api/users/show/${userData.userID}`)
        .then(response => {
          // console.log(response.data)
          this.show = false
          this.User = response.data
          this.getfriend(response.data.invite_code)
        })
        .catch(error => console.log(error))
    },
    getAgent() {
      this.show = true
      this.$http
        .get('https://api.ufabangkok.com/api/agent/show')
        .then(response => {
          // console.log(response.data)
          this.show = false
          this.Agent = response.data
        })
        .catch(error => console.log(error))
    },
    getbalance(username) {
      this.show = true
      const params = {
        username,
      }
      this.$http
        .get('https://api.ufabangkok.com/api/balance/getbalance', { params })
        .then(response => {
          // console.log(response.data.ListGames)
          this.show = false
          this.balance = response.data
        })
        .catch(error => console.log(error))
    },
    submit(friend) {
      this.show = true
      if (friend.turndiff <= 0) {
        const formData = {
          fid: friend.id,
        }
        this.$http
          .post('https://api.ufabangkok.com/api/promotion/getfriendbonus', formData)
          .then(response => {
            this.show = false
            this.Success(response.data.message)
            this.amount = ''
            this.getUser(this.userData)
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
          })
      } else {
        this.show = false
        this.SwalError(
          'ไม่สามารถโยกเครดิตได้ เนื่องจากยังไม่เป็นไปตามเงื่อนไขที่กำหนดไว้',
        )
      }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: 'SUCCESS',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color:#000">ขออภัยค่ะ!<span>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
  },
}
</script>

<style scoped>
.container {
  max-width: 540px;
}
.btn {
  border-radius: 38px;
  width: 100vw;
  color: #fff !important;
  background-image: linear-gradient(249deg, #ff00db, #8726ff);
}
.balance-cl {
  background-color: #ffc800;
  border-radius: 15px;
  padding: 24px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: #000;
  position: relative;
}
p.highlight {
  line-height: 1.9;
  font-size: 1.8rem;
}
.pic {
  max-width: 210px;
}
@media only screen and (max-width: 600px) {
  .pic {
    margin-top: 35px;
    max-width: 160px;
  }
  .pic2 {
    margin-top: 35px;
    max-width: 40px;
  }
}
.m-space {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 35px;
  margin-left: 1px;
  margin-right: 1px;
}
a {
  color: #fff;
}
.copy {
  margin-top: 15px;
  border: #ffc800;
  padding: 10px 25px;
  background-color: #ffc800;
  border-radius: 38px;
}
small {
  font-size: 1rem;
}
/* asdasd */
</style>
